/**
 * クエリパラメータを生成する
 *
 * @param params
 * @returns query
 */
export const buildQueryString = (params: {
  [key: string]: string | number | null
}) => {
  const query = Object.keys(params)
    .filter(key => params[key] !== '' && params[key] !== null)
    .map(
      key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          params[key] as string,
        )}`,
    )
    .join('&')
  return query ? `?${query}` : ''
}
